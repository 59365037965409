import React, { memo } from "react"
import Img from "gatsby-image"
const Dynamics365OptimizationImageWithTextSection = ({ content, data }) => {
    let upgrade
    let OdooUpdrage
    let OdooOPtimitaztion
    let OdooSupporte
    let OdooIntegration
    if (typeof window !== "undefined") {
        upgrade = window.location.pathname === "/dynamics365/upgrade"
        OdooUpdrage = window.location.pathname === "/Odoo/upgrade"
        OdooOPtimitaztion = window.location.pathname === "/Odoo/optimization"
        OdooSupporte = window.location.pathname === "/Odoo/support"
        OdooIntegration = window.location.pathname === "/Odoo/integration"
    }
    return (
        <div className="ImageWithTextSection">
            <Img fluid={data.SecondDivImg.childImageSharp.fluid} alt="img" />
            <div>
                <h1>{content.heading}</h1>
                <p>{content.description}</p>
                {OdooUpdrage ||
                    upgrade ||
                    OdooOPtimitaztion ||
                    OdooSupporte ||
                    OdooIntegration ? (
                    <p>{content.d2}</p>
                ) : (
                    ""
                )}
            </div>
        </div>
    )
}

export default memo(Dynamics365OptimizationImageWithTextSection)
